import React from 'react';

import PageLayout from '../views/Layout/Page';
import NotFound from '../views/NotFound';

export default function NotFoundPage() {
  return (
    <PageLayout pageview="not_found">
      <NotFound />
    </PageLayout>
  );
}
